import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Box, Layout, Grid, CaseStudy } from '../components';
import { appENV } from '../config';

const CaseStudies = () => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        caseStudies {
          edges {
            node {
              id
              title
              slug
              case_options {
                caseStudyFeatured
                caseStudyProduction
              }
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    }
  `);

  const allCaseStudies = data.wpgraphql.caseStudies.edges;
  const caseStudies = allCaseStudies.filter(item => {
    if (appENV === 'production') {
      return item.node.case_options.caseStudyProduction;
    }

    return true;
  });
  const featuredCase = caseStudies?.find(
    item => item.node.case_options.caseStudyFeatured
  );
  const otherCases = caseStudies?.filter(
    item => !item.node.case_options.caseStudyFeatured
  );

  return (
    <Layout title="Case Studies">
      <Container mt={{ _: 8, md: 9 }}>
        <Box p={{ _: 2, md: 0 }} mb={{ _: 8, md: 0 }}>
          {featuredCase && (
            <CaseStudy type="large" caseStudy={featuredCase.node} />
          )}
        </Box>
        <Box p={{ _: 2, md: 0 }} mt={{ _: 6, md: 10 }}>
          <Grid
            // gap={{ xs: 2, md: 10 }}
            gap="32px"
            gapSm="15px"
            gridRowGap={9}
            gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr' }}
          >
            {otherCases.map(item => (
              <CaseStudy key={item.node.id} caseStudy={item.node} />
            ))}
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
};

export default CaseStudies;
